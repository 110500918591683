import React from "react"
import globalStyles from '../../style/global.module.scss'


const landingSection = ({ h1, title, subtitle }) => {
    return (
        <section className={globalStyles.landingSectionContainer}>
            <div data-sal={"slide-right"}
            data-sal-delay = {"200"}
            data-sal-duration = {"500"}
       
                className={globalStyles.landingSection}>
                <h1>{h1}</h1>
                <p className={globalStyles.title}>{title}</p>
                <p className={globalStyles.subtitle}>{subtitle}</p>
            </div>
        </section>
    )
}

export default landingSection